/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

          $(".mobile-nav-toggle").click(function(e){
            $(".mobile-navigation-container").toggleClass("animate");
            e.preventDefault();
          });



         $(window).scroll(function() {
          if ($(window).scrollTop() > 100) {
            $(".header-bar").addClass("animated");

          } else {
        
            $(".header-bar").removeClass("animated");
          }
        });



          $(window).scroll(function () {
            $('.burritos-float, .splitting-taco-float, .tacos-floating-vertical').each(function (i) {
                var bottom_of_object = $(this).offset().top + ($(this).outerHeight() * 0.05);
                var bottom_of_window = $(window).scrollTop() + $(window).height();
                if (bottom_of_window > bottom_of_object) {
                    $(this).addClass('animate');
                }
            });


             $(".table-menu tr").each(function(index){

                var bottom_of_object = $(this).offset().top + ($(this).outerHeight() * 0.05);
                var bottom_of_window = $(window).scrollTop() + $(window).height();
                if (bottom_of_window > bottom_of_object) {
                     $(this).delay(index*1000).addClass("animate");
                }
               
              });



             $('.fadein-container').each(function (i) {
                var bottom_of_object = $(this).offset().top + ($(this).outerHeight() * 0.05);
                var bottom_of_window = $(window).scrollTop() + $(window).height();
                if (bottom_of_window > bottom_of_object) {
                    $(this).addClass('animate');
                }
            });
          });


         

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        $("#Pyramid").addClass("animate");
        $("#MID_EAST, #TACOS").addClass("animate");
        $("#Palm_Tree, #Palm_Fruit").addClass("animate");
        $("#Trunk").addClass("animate");

      }
    },
    // About us page, note the change from about-us to about_us.
    'catering': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function() {
        

        


      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
